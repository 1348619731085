import React, { useState } from 'react';
import './physioContent.css';
import VideoSelector from './videoSelector';

const PhysioVideos = ({ userID }) => {
  const [videos, setVideos] = useState([]);
  const handleVideoSelect = (video) => {
    setVideos([...videos, { id: video.id, title: video.title, src: video.url }]);
  };

  return (
    <div className="container">
      <h2>Physio Videos</h2>
      <div className="video-scroll-container">
      <VideoSelector userId={userID} onSelectVideo={handleVideoSelect} />
      </div>
    </div>
  );
}

export default PhysioVideos;
