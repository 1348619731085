import React, { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import LoginPage from './login/login'
import MainContent from './mainContent/mainContent'
import Cube from './Cube'
import CreateAthleteProfile from './createAthleteProfile'
import CreatephysioProfile from './createPhysioProfile'
import './signup.css'

const App = () => {
  const { isAuthenticated, user } = useAuth0()
  const [userRole, setUserRole] = useState('')
  const [userDataID, setUserDataID] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [showRoleSelection, setShowRoleSelection] = useState(false)
  const [selectedRole, setSelectedRole] = useState('')
  const [acceptedDataCollection, setAcceptedDataCollection] = useState(false)
  const [profileCreated, setProfileCreated] = useState(false)
  
  const [backgroundImage, setBackgroundImage] = useState('')

  const fetchData = async () => {
    if (isAuthenticated && user) {
      try {
        const response = await fetch(
          `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/users/getDLSUUID?email=${user.email}`
        )

        if (response.status === 200) {
          const data = await response.json()
          setUserRole(data.role)
          setUserDataID(data.dataID)
          if (data.role === 'athlete') {
            const response1 = await fetch(
              `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/athleteProfile/getProfile?dataID=${data.dataID}`
            )
            if (response1.status === 200) {
              setProfileCreated(true)
            } else {
              setProfileCreated(false)
            }
          } else {
            const response3 = await fetch(
              `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/joinPhysio/getUsers?dataID=${data.dataID}`
            )
            if (response3.status === 200) {
              setProfileCreated(true)
            } else {
              setProfileCreated(false)
            }
          }
          setIsLoading(false)
        } else {
          // If user doesn't exist, show the role selection UI
          setShowRoleSelection(true)
          setProfileCreated(false)
          setIsLoading(false)
        }
      } catch (error) {
        console.error('Error fetching user data:', error)
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    const images = [
      require('./images/background1.jpg'),
      require('./images/background2.jpg'),
      require('./images/background3.jpg'),
      require('./images/background4.jpg'),
      require('./images/background5.jpg')
    ]
    const randomImage = images[Math.floor(Math.random() * images.length)]
    setBackgroundImage(randomImage)
    fetchData()
  }, [isAuthenticated, user])

  const handleRoleSubmit = async () => {
    if (!acceptedDataCollection) {
      alert('You must accept the data collection policy to proceed.')
      return
    }

    try {
      const createUserResponse = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/users/create-user`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email: user.email, role: selectedRole })
        }
      )

      if (createUserResponse.ok) {
        setShowRoleSelection(false)
        // Re-fetch the user data after submitting the role
        await fetchData()
      } else {
        console.error('Error creating user:', createUserResponse.statusText)
      }
    } catch (error) {
      console.error('Error creating user:', error)
    }
  }

  const handleProfileCreated = () => {
    setProfileCreated(true)
    console.log('Profile created successfully')
  }

  if (!isAuthenticated) {
    return <LoginPage />
  }

  if (isLoading) {
    return (
      <div>
        <Cube />
      </div>
    )
  }

  if (showRoleSelection) {
    return (
      <div className='mainContainer-SelectRole'
      style={{
        backgroundImage: `url(${backgroundImage})`
      }}
      >
        <div className='mainContent-SelectRole'>
          <h2>Select Your Role</h2>
          <select
            value={selectedRole}
            onChange={e => setSelectedRole(e.target.value)}
          >
            <option value=''>Select a role</option>
            <option value='athlete'>Athlete</option>
            <option value='physio'>Physio</option>
            <option value='pt'>Personal Trainer</option>
            <option value='manager'>Manager</option>
          </select>

          <div>
            <p>Privacy Policy Agreement:</p>
            <input
              type='checkbox'
              checked={acceptedDataCollection}
              onChange={e => setAcceptedDataCollection(e.target.checked)}
            />
            <label>
              I accept the collection of data for use in this app.<br/>
              <a
                href='/privacy-policy'
                target='_blank'
                rel='noopener noreferrer'
              >
                View Privacy Policy
              </a>
            </label>
          </div>
          <button onClick={handleRoleSubmit} disabled={!selectedRole}>
            Submit
          </button>
        </div>
      </div>
    )
  }

  // Render CreateAthleteProfile if needed
  if (userRole === 'athlete' && !profileCreated) {
    return (
      <div>
        <CreateAthleteProfile
          onProfileCreated={handleProfileCreated}
          dataID={userDataID}
        />
      </div>
    )
  }

  if ((userRole === 'pt' || userRole === 'physio') && !profileCreated) {
    return (
      <div>
        <CreatephysioProfile
          onProfileCreated={handleProfileCreated}
          dataID={userDataID}
        />
      </div>
    )
  }

  // Render MainContent if the profile is created
  if (profileCreated) {
    return (
      <div>
        <MainContent role={userRole} dataID={userDataID} />
      </div>
    )
  }

  // Default loading state while athlete profile is being created
  return (
    <div>
      <Cube />
    </div>
  )
}

export default App
