import React, { useState, useEffect } from 'react';
import WodCard from './WodCard';
import './WodSelector.css';

const WodSelector = () => {
  const [wodsData, setWodsData] = useState([]);
  const [currentWodIndex, setCurrentWodIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/Wods/getWods');
        const data = await response.json();
        setWodsData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleNextWod = () => {
    setCurrentWodIndex((prevIndex) =>
      prevIndex === wodsData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevWod = () => {
    setCurrentWodIndex((prevIndex) =>
      prevIndex === 0 ? wodsData.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="wod-selector">
      <h2>WOD Selector</h2>
      <div className="wod-card-container">
        {wodsData.length > 0 ? (
          <WodCard wod={wodsData[currentWodIndex]} />
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <div className="button-container">
        <button onClick={handlePrevWod} disabled={wodsData.length === 0}>Previous</button>
        <button onClick={handleNextWod} disabled={wodsData.length === 0}>Next</button>
      </div>
    </div>
  );
};

export default WodSelector;
