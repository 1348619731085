import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import "./createProfile.css";

const CreatephysioProfile = ({ onProfileCreated, dataID }) => {
  const [name, setName] = useState("");
  const [licenceKey, setLicenceKey] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

  useEffect(() => {
    // Array of image paths
    const images = [
      require("./images/background1.jpg"),
      require("./images/background2.jpg"),
      require("./images/background3.jpg"),
      require("./images/background4.jpg"),
      require("./images/background5.jpg"),
    ];

    // Select a random image
    const randomImage = images[Math.floor(Math.random() * images.length)];
    setBackgroundImage(randomImage);
  }, []);

  const createNameRelation = async (nameRelationData) => {
    const response = await fetch(
      "https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/name-Relation/create",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(nameRelationData),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to create name relation");
    }

    return response;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const nameRelationData = {
      dataID: dataID,
      name: CryptoJS.AES.encrypt(name, encryptionKey).toString(),
    };

    try {
      // Call the function to create name relation
      const CNRResponse = await createNameRelation(nameRelationData);
      console.log("Name relation: ", CNRResponse);

      // Assuming profile creation is successful, you call onProfileCreated
      if (onProfileCreated) {
        onProfileCreated();
      }
    } catch (error) {
      console.error("Error creating profile:", error);
    }
  };

  return (
    <div
      className="mainContainer"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className=" mainContent">
        <h2>Create Profile</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              Name:
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Licence key:
              <input
                type="text"
                value={licenceKey}
                onChange={(e) => setLicenceKey(e.target.value)}
              />
            </label>
          </div>
          <div>
            <button type="submit">Create Profile</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreatephysioProfile;
