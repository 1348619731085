import React from 'react';
import './WodCard.css';

const WodCard = ({ wod }) => {
  return (
    <div className='wod-card'>
      <h3>{wod.title.S}</h3>
      <p>Type: {wod.type.S}</p>
      <p>Time Cutoff: {wod.time.S}</p>
      <p>Movements:</p>
      <ul>
        {wod.movements.L.map((movement, index) => (
          <li key={index}>{movement.S}</li>
        ))}
      </ul>
      {wod['additional-info'] && (
        <div>
          <h4>Additional Information:</h4>
          <pre className='addInfo_Text' style={{whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>{wod['additional-info'].S}</pre>
        </div>
      )}
    </div>
  );
};

export default WodCard;
