import React from "react";
import "./Header.css";
import Logo from '../images/logoLight.png';

const Header = ({ onLinkClick }) => {
  return (
    <div className="header">
        <img src={Logo} alt="Logo" className="logo"></img>
      <nav className="navbar">
        <ul>
          <li>
            <a onClick={() => onLinkClick("home")}>Home</a>
          </li>
          <li>
            <a onClick={() => onLinkClick("about")}>About</a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
