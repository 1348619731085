import React, { useState, useEffect } from 'react';
import PhysioProgramming from './physioProgramming';
import PhysioVideos from './physioVideos';
import PhysioReportNewIssue from './physioReportNewIssue';
import AddPhysio from './addPhysio';
import './physioContent.css';

const PhysioContent = ({ userID }) => {
  const [hasPhysio, setHasPhysio] = useState(null); // null indicates loading state

  useEffect(() => {
    const checkUserPhysio = async () => {
      try {
        const userid = userID.dataID;
        const response = await fetch(`https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/athlete/checkHasPhysio?UserID=${userid}`);
        const data = await response.json();
        setHasPhysio(data);
      } catch (error) {
        console.error('Error fetching user physio status:', error);
      }
    };

    checkUserPhysio();
  }, [userID]);

  if (hasPhysio === null) {
    return <div>Loading...</div>; // Show a loading state while checking
  }

  return hasPhysio ? (
    <div>
      <PhysioProgramming userID={userID}/>
      <PhysioVideos userID={userID}/>
      <PhysioReportNewIssue userID={userID} />
    </div>
  ) : (
    <div>
      <AddPhysio userID={userID}/>
      {/* You can add a form or link to add a physio here */}
    </div>
  );
};

export default PhysioContent;
