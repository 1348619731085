import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import './login.css';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <button className="login_button" onClick={() => loginWithRedirect()}
  style={{ background: '#BDBCB9', fontWeight: 'bold'}}
  >Log In</button>;
};

export default LoginButton;