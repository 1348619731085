// PhysioProgramming.jsx
import React, { useEffect, useState } from 'react';
import './physioContent.css';

const PhysioProgramming = ({ userID }) => {
  const [programmingData, setProgrammingData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProgrammingData = async () => {
      try {
        const userid = userID.dataID;
        const response = await fetch(`https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/athlete/getPhysioPTData?userID=${userid}`);
        const result = await response.json();

        if (response.status === 200 && result.length > 0 && result[0].Programming) {
          setProgrammingData(result[0].Programming);
        }
      } catch (error) {
        console.error('Error fetching programming data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProgrammingData();
  }, [userID.UserID]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!programmingData.length) {
    return <div>No programming data available.</div>;
  }

  return (
    <div className="container">
      <h2>Physio Programming</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Exercise</th>
            <th>Sets</th>
            <th>Reps</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {programmingData.map((exercise, index) => (
            <tr key={index}>
              <td>{exercise.Exercise}</td>
              <td>{exercise.Sets}</td>
              <td>{exercise.Reps}</td>
              <td>{exercise.Notes}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PhysioProgramming;
