import { React, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import PatientDropdown from "./PatientDropdown";
import PhysioProgramming from "./PhysioProgramming";
import PhysioVideos from "./PhysioVideos";
import TrainingSchedule from "./TrainingSchedule";

const PTMainContent = (dataID) => {
  const [selectedLink, setSelectedLink] = useState("home");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientData, setPatientData] = useState(null);
  let contentComponent;

  const handlePatientSelect = async (patient) => {
    setSelectedPatient(patient);
    if (patient && patient.ptID) {
      try {
        const response = await fetch(
          `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/physio/PTData/get?userID=${patient.ptID}`
        );
        const data = await response.json();
        setPatientData(data);
      } catch (error) {
        console.error("Error fetching patient data:", error);
      }
    }
  };

  switch (selectedLink) {
    case "home":
      contentComponent = (
        <div>
          <PatientDropdown dataID={dataID} onSelect={handlePatientSelect} />
          {patientData && (
            <>
              <h2 className="patientName">{selectedPatient.ptName}</h2>
              <TrainingSchedule patient={patientData} />
              <PhysioProgramming patient={patientData} />
              <PhysioVideos patient={patientData} />
            </>
          )}
        </div>
      );
      break;
    default:
      contentComponent = null;
  }

  const handleLinkClick = (link) => {
    setSelectedLink(link);
  };

  return (
    <div>
      <Header onLinkClick={handleLinkClick} />
      {contentComponent}
      <Footer onLinkClick={handleLinkClick} />
    </div>
  );
};

export default PTMainContent;
