import React from "react";

const PrivacyPolicyContent = () => {
    return (
        <div>
            <h2>Privacy Policy</h2>
            
            <section>
                <h3>1. Introduction</h3>
                <p>
                    We are committed to protecting your personal data and respecting your privacy. This policy explains how we collect, use, and protect your personal information, particularly when using our online services.
                </p>
            </section>
            
            <section>
                <h3>2. Data We Collect</h3>
                <p>
                    We may collect and process the following data about you:
                </p>
                <ul>
                    <li>Personal identification information (e.g., name, email address, phone number)</li>
                    <li>Health data (e.g., medical history, treatment information)</li>
                    <li>Usage data (e.g., information about how you use our services)</li>
                </ul>
            </section>

            <section>
                <h3>3. How We Use Your Data</h3>
                <p>
                    Your data may be used for the following purposes:
                </p>
                <ul>
                    <li>To provide and maintain our services</li>
                    <li>To manage your account</li>
                    <li>To improve our services and develop new features</li>
                    <li>To communicate with you regarding your account or services</li>
                    <li>To comply with legal obligations</li>
                </ul>
            </section>

            <section>
                <h3>4. Legal Basis for Processing Your Data</h3>
                <p>
                    We process your personal data based on the following legal grounds:
                </p>
                <ul>
                    <li>Consent: Where you have provided explicit consent for us to process your data.</li>
                    <li>Contract: Where processing is necessary for the performance of a contract with you.</li>
                    <li>Legal obligation: Where processing is necessary to comply with our legal obligations.</li>
                    <li>Legitimate interests: Where processing is necessary for our legitimate interests, provided they do not override your rights.</li>
                </ul>
            </section>

            <section>
                <h3>5. Data Sharing and Disclosure</h3>
                <p>
                    We do not share your personal data with third parties except:
                </p>
                <ul>
                    <li>When required by law or to respond to legal process</li>
                    <li>With service providers who assist us in operating our services, under strict confidentiality agreements</li>
                    <li>If you provide explicit consent to share your data</li>
                </ul>
            </section>

            <section>
                <h3>6. Data Retention</h3>
                <p>
                    We retain your personal data only for as long as necessary to fulfill the purposes for which it was collected, including for the purposes of satisfying any legal, accounting, or reporting requirements.
                </p>
            </section>

            <section>
                <h3>7. Your Rights</h3>
                <p>
                    Under data protection laws, you have the following rights:
                </p>
                <ul>
                    <li>The right to access the personal data we hold about you</li>
                    <li>The right to correct or update your personal data</li>
                    <li>The right to request the deletion of your personal data</li>
                    <li>The right to restrict the processing of your data</li>
                    <li>The right to data portability</li>
                    <li>The right to object to the processing of your data</li>
                </ul>
                <p>
                    To exercise these rights, please contact us at [Contact Information].
                </p>
            </section>

            <section>
                <h3>8. Data Security</h3>
                <p>
                    We take the security of your personal data seriously and implement appropriate technical and organizational measures to protect it from unauthorized access, alteration, disclosure, or destruction.
                </p>
            </section>

            <section>
                <h3>9. Changes to This Privacy Policy</h3>
                <p>
                    We may update this privacy policy from time to time. Any changes will be posted on this page, and where appropriate, notified to you by email.
                </p>
            </section>

            <section>
                <h3>10. Contact Us</h3>
                <p>
                    If you have any questions about this privacy policy or our data practices, please contact us at [Contact Information].
                </p>
            </section>
        </div>
    );
}

export default PrivacyPolicyContent;
