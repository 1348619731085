import React, { useState, useEffect } from 'react'
import LoginButton from './loginAuth'
import './login.css'
import Header from './loginHeader'
import AboutAppPage from '../aboutApp'
import PrivacyPolicyContent from '../mainContent/privacyPolicy'
import Logo from '../images/logoLight.png'

const LoginPage = () => {
  const [selectedLink, setSelectedLink] = useState('home')
  const [backgroundImage, setBackgroundImage] = useState('')

  useEffect(() => {
    // Array of image paths
    const images = [
      require('../images/background1.jpg'),
      require('../images/background2.jpg'),
      require('../images/background3.jpg'),
      require('../images/background4.jpg'),
      require('../images/background5.jpg')
    ]

    // Select a random image
    const randomImage = images[Math.floor(Math.random() * images.length)]
    setBackgroundImage(randomImage)
  }, [])

  let contentComponent

  const handleLinkClick = link => {
    setSelectedLink(link)
  }

  switch (selectedLink) {
    case 'home':
      contentComponent = (
        <div>
          <div
            className='mainContainer'
            style={{
              backgroundImage: `url(${backgroundImage})`
            }}
          >
            <div className='mainContent'>
              {/* <h2 className='login_title'>
                <span className='digilab'>Digilab_Sports</span>{' '}
                <span className='athlio'>AthlioConnect</span>
              </h2> */}
              <div className='logoContainer'>
                <img src={Logo} alt='athlioconnect logo' className='Loginlogo'></img>
              </div>

              <LoginButton />
            </div>
          </div>
        </div>
      )
      break

    case 'about':
      contentComponent = (
        <div>
          <AboutAppPage onLinkClick={handleLinkClick} />
        </div>
      )
      break

    case 'privacy':
      contentComponent = (
        <div>
          <PrivacyPolicyContent />
        </div>
      )
      break

    default:
      contentComponent = null
  }

  return (
    <div>
      <Header onLinkClick={handleLinkClick} />
      {contentComponent}
    </div>
  )
}

export default LoginPage
