import React, { useEffect, useState } from 'react';
import './physioContent.css';

const PatientDropdown = ({ dataID, onSelect }) => {
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const dataid = dataID.dataID
        const response = await fetch(`https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/PT/getPTs?ptID=${dataid}`);
        const data = await response.json();
        setPatients(data);
      } catch (error) {
        console.error('Error fetching patients:', error);
      }
    };

    fetchPatients();
  }, [dataID]);

  const handleChange = (event) => {
    const selectedPatient = patients.find(patient => patient.ptID === event.target.value);
    onSelect(selectedPatient);
  };

  return (
    <div className="form-group">
      <label htmlFor="patientSelect">Select Patient:</label>
      <select id="patientSelect" onChange={handleChange}>
        <option value="">--Please choose a patient--</option>
        {patients.map((patient) => (
          <option key={patient.ptID} value={patient.ptID}>
            {patient.ptName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default PatientDropdown;
