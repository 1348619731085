import React from 'react';
import './physioContent.css';

const NewAlerts = ({ patient }) => {
  return (
    <div className="container">
      <h2>New Alerts</h2>
      {patient.map((item) => (
        <div key={item.Alert.alertID} className="alert-container">
          <p><strong>Alert Message:</strong> {item.Alert.alertMessage}</p>
          <p><strong>Timestamp:</strong> {new Date(item.Alert.alertTimestamp).toLocaleString()}</p>
          <p><strong>Type:</strong> {item.Alert.alertType}</p>
          <p><strong>Body Part:</strong> {item.Alert.alertBodyPart}</p>
        </div>
      ))}
    </div>
  );
};

export default NewAlerts;
