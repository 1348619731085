import React, { useState } from "react";
import "./physioContent.css";

const PhysioProgramming = ({ patient }) => {
  const currentPatient = patient[0];
  const [programming, setProgramming] = useState(currentPatient.Programming);
  const [newExercise, setNewExercise] = useState({
    Exercise: "",
    Sets: "",
    Reps: "",
    Notes: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewExercise({ ...newExercise, [name]: value });
  };

  const addExercise = () => {
    const updatedProgramming = [...programming, newExercise];
    setProgramming(updatedProgramming);
    updateProgramming(updatedProgramming);
    setNewExercise({
      Exercise: "",
      Sets: "",
      Reps: "",
      Notes: "",
    });
  };

  const removeExercise = (index) => {
    const updatedProgramming = programming.filter((_, i) => i !== index);
    setProgramming(updatedProgramming);
    updateProgramming(updatedProgramming);
  };

  const updateProgramming = async (updatedProgramming) => {
    const response = await fetch(
      `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/physio/programming/updatePTProgramming?userId=${currentPatient.UserID}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Programming: updatedProgramming }),
      }
    );
    const data = await response.json();
    console.log(data);
  };

  return (
    <div className="container">
      <h2>Physio Programming</h2>
      <div className="table-wrapper">
        <table className="table">
          <thead>
            <tr>
              <th>Exercise</th>
              <th>Sets</th>
              <th>Reps</th>
              <th>Notes</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {programming.map((program, index) => (
              <tr key={index}>
                <td>{program.Exercise}</td>
                <td>{program.Sets}</td>
                <td>{program.Reps}</td>
                <td>{program.Notes}</td>
                <td>
                  <button onClick={() => removeExercise(index)}>Remove</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="form-group">
        <h3>Add New Exercise</h3>
        <input
          type="text"
          name="Exercise"
          placeholder="Exercise"
          value={newExercise.Exercise}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="Sets"
          placeholder="Sets"
          value={newExercise.Sets}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="Reps"
          placeholder="Reps"
          value={newExercise.Reps}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="Notes"
          placeholder="Notes"
          value={newExercise.Notes}
          onChange={handleInputChange}
        />
        <button onClick={addExercise}>Add Exercise</button>
      </div>
      <button onClick={() => updateProgramming(programming)}>
        Update Programming
      </button>
    </div>
  );
};

export default PhysioProgramming;
