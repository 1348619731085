import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import Cube from "../Cube";

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  console.log(user)

  if (isLoading) {
    return <div><Cube/></div>;
  }

  return (
    isAuthenticated && (
      <div>
        <img src={user.picture} alt={user.name} />
        <h2>{user.name}</h2>
        <p>To change password please please log out and and use the Auth0 options.</p>
        <p>Digilab/AthlioConnect does not have access to your password info</p>
      </div>
    )
  );
};

export default Profile;