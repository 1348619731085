/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import AthleteMainContent from './athlete/athleteMainContent';
import PhysioMainContent from './physio/physioMainContent';
import PTMainContent from './pt/ptMainContent';

const MainContent = ({ role, dataID }) => {
  // State to store the user type; replace with your actual user type logic
  const [userType, setUserType] = useState('physio'); // or 'pt' or 'athlete'

  return (
    <div>
      {role === 'physio' && <PhysioMainContent dataID={dataID} />}
      {role === 'athlete' && <AthleteMainContent dataID={dataID} />}
      {role === 'pt' && <PTMainContent dataID={dataID} />}
    </div>
  );
};

export default MainContent;
