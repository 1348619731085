import React, { useRef, useState } from 'react';
import './physioContent.css';
import VideoSelector from './videoSelector';
import { FaVideo, FaStop } from 'react-icons/fa';

const PhysioVideos = ({ patient }) => {
  const currentPatient = patient[0].UserID;
  const [videos, setVideos] = useState([
    { id: 1, title: 'Squat Tutorial', src: '' },
    { id: 2, title: 'Deadlift Tutorial', src: '' },
  ]);

  const [recording, setRecording] = useState(false);
  const [videoURL, setVideoURL] = useState('');
  const [showVideoNameInput, setShowVideoNameInput] = useState(false);
  const [videoName, setVideoName] = useState('');
  const [reloadVideos, setReloadVideos] = useState(false);
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const chunks = useRef([]);

  const handleArchive = (id) => {
    setVideos(videos.filter((video) => video.id !== id));
  };

  const handleCreate = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      videoRef.current.srcObject = stream;
      mediaRecorderRef.current = new MediaRecorder(stream);

      chunks.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        chunks.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks.current, { type: 'video/mp4' });
        const url = URL.createObjectURL(blob);
        setVideoURL(url);
        setShowVideoNameInput(true);  // Show the input field when recording stops
      };

      mediaRecorderRef.current.start();
      setRecording(true);
    } catch (error) {
      console.error('Error accessing media devices:', error);
    }
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
    setRecording(false);
  };

  const handleVideoNameSubmit = async () => {
    const videoId = Date.now();
    const blob = new Blob(chunks.current, { type: 'video/mp4' });
    const finalVideoName = `${videoName} - ${currentPatient.name}`;
    const url = URL.createObjectURL(blob);
    setVideos([...videos, { id: videoId, title: finalVideoName, src: url }]);

    // Generate the presigned URL on the backend
    try {
      //const fileName = `video-${videoId}.mp4`;
      const fileName = `${currentPatient}+${videoName}.mp4`
      const presignedResponse = await fetch('https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/videos/upload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ file_name: fileName }),
      });

      if (!presignedResponse.ok) {
        throw new Error('Failed to get presigned URL');
      }

      const presignedUrl = await presignedResponse.text();

      // Upload the video to S3 using the presigned URL
      const uploadResponse = await fetch(presignedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        body: blob,
      });

      if (uploadResponse.ok) {
        console.log('Video uploaded successfully');
        setReloadVideos(!reloadVideos)
      } else {
        console.error('Failed to upload video:', uploadResponse.statusText);
      }
    } catch (error) {
      console.error('Error during upload:', error);
    }

    setShowVideoNameInput(false);
    setVideoName('');  // Clear the input field
  };

  const handleVideoSelect = (video) => {
    setVideos([...videos, { id: video.id, title: video.title, src: video.url }]);
  };

  return (
    <div className="container">
      <h2>Physio Videos</h2>
      <div className="video-scroll-container">
      <VideoSelector userId={currentPatient} reload={reloadVideos} onSelectVideo={handleVideoSelect} />
        
      </div>
      <div className="video-capture-container">
        <video ref={videoRef} autoPlay playsInline width="300" height="200" style={{ display: recording ? 'block' : 'none' }}></video>
        {recording ? (
          <button onClick={stopRecording}><FaStop /> Stop Recording</button>
        ) : (
          <button onClick={handleCreate}><FaVideo /> Create New Video</button>
        )}
      </div>
      {videoURL && (
        <div>
          <h3>Recorded Video:</h3>
          <video src={videoURL} controls width="300" height="200"></video>
        </div>
      )}
      {showVideoNameInput && (
        <div className="video-name-input">
          <h3>Enter Video Name</h3>
          <input
            type="text"
            value={videoName}
            onChange={(e) => setVideoName(e.target.value)}
            placeholder="Enter video name"
          />
          <button onClick={handleVideoNameSubmit}>Submit</button>
        </div>
      )}
    </div>
  );
};

export default PhysioVideos;
