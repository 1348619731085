import React from 'react';
import './header.css';
import LogoutButton from '../../login/logout'

const Header = ({ onLinkClick }) => {
  return (
    <header>
      <div className="logo-placeholder">AthlioConnect</div>
      <h1 className="header-title">PT Dashboard</h1>
      <nav>
        <ul>
          <li><a onClick={() => onLinkClick('home')}>Home</a></li>
          <li><a onClick={() => onLinkClick('physio')}>Physio</a></li>
          <li><a onClick={() => onLinkClick('about')}>About</a></li>
          <li><a onClick={() => onLinkClick('profile')}>Profile</a></li>
          <li><LogoutButton/></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
