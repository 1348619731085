import React, { useState } from 'react';
import './physioContent.css';

const PatientTrainingStatus = ({ patient }) => {
  const [status, setStatus] = useState(patient[0].status);

  const handleChangeStatus = async (newStatus) => {
    setStatus(newStatus);

    try {
      const response = await fetch(`https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/physio/PTData/updateStatus?userID=${patient[0].UserID}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus }),
      });

      if (!response.ok) {
        throw new Error('Failed to update status');
      }

      const data = await response.json();
      console.log(`https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/physio/PTData/updateStatus?userID=${patient[0].UserID}`)
      console.log('Status updated successfully:', data);
    } catch (error) {
      console.error('Error updating status:', error);
    } 
  };

  return (
    <div className="container">
      <h2>Training Status</h2>
      <p>Current training status: {status}</p>
      <select
        value={status}
        onChange={(e) => handleChangeStatus(e.target.value)}
      >
        <option value="Active">Active</option>
        <option value="Injured">Injured</option>
        <option value="Recovery">Recovery</option>
        <option value="Needs Assessment">Needs Assessment</option>
        <option value="Performance">Performance</option>
      </select>
    </div>
  );
};

export default PatientTrainingStatus;
