import React from 'react';
import './about.css';

const About = () => {
  return (
    <div className="about-container">
      <h1>About This App</h1>
      <p>This application is currently in beta. We appreciate your understanding as we continue to improve and add new features.</p>
      
      <h2>GDPR Compliance</h2>
      <p>We are committed to ensuring full GDPR compliance. Our team is working diligently to implement necessary features to protect your data and privacy. Stay tuned for updates on this!</p>
      
      <h2>Add to Homescreen on iPhone</h2>
      <p>To add this app to your homescreen on an iPhone:</p>
      <ul>
        <li>Open Safari and navigate to this app.</li>
        <li>Tap the "Share" button at the bottom of the screen (it looks like a square with an arrow pointing upward).</li>
        <li>Scroll down in the Share menu and select "Add to Home Screen".</li>
        <li>Choose a name for the app icon and tap "Add".</li>
        <li>The app icon will now appear on your homescreen.</li>
      </ul>

      <h2>Add to Homescreen on Android</h2>
      <p>To add this app to your homescreen on an Android device:</p>
      <ul>
        <li>Open Chrome and navigate to this app.</li>
        <li>Tap the three vertical dots in the top-right corner of the browser.</li>
        <li>Select "Add to Home Screen" from the dropdown menu.</li>
        <li>Choose a name for the app icon and tap "Add".</li>
        <li>The app icon will now appear on your homescreen.</li>
      </ul>

      <h2>Upcoming Features</h2>
      <ul>
        <li>User profiles</li>
        <li>Enhanced data analytics and reporting</li>
        <li>Integration with third-party services</li>
        <li>Improved UI/UX</li>
        <li>Improved mobile responsiveness</li>
        <li>Daily WOD Uploads</li>
      </ul>
      
      <h2>Feedback</h2>
      <ul>
        <li>We welcome your feedback during this beta phase to help us shape the future of the app!</li>
        <li>For any bugs, problems, queries or improvement ideas please email tedheywood01@gmail.com Please start the subject with 'AthioConnect'</li>
      </ul>
    </div>
  );
}

export default About;
