import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe('pk_test_51Psq2PRuxSNwOr4wqymLyf8OJkc5T8rLjnVJ9qHxaszpqUUl1LZGBiAS8dcWlmQW6E7hRPUYIhgmOU5ECadnNCvE00zWwy24nB');

const BookingPage = ({ practitioner, patient }) => {
  const [appStartTime, setAppStartTime] = useState("");
  const [appEndTime, setAppEndTime] = useState("");

  const [physioType, setPhysioType] = useState("senior");
  const [clientSecret, setClientSecret] = useState("");
  const [isPaymentIntentCreated, setIsPaymentIntentCreated] = useState(false);
  const handleBookWithTime = () => {
    setPhysioType('senior')
    handleBuyNow();
  };

  const handleBuyNow = async () => {
    if (!physioType) {
      alert("Please select a physio type");
      return;
    }

    try {
      // Send request to your backend to create a PaymentIntent
      const response = await fetch(
        "http://localhost:5000/create-payment-intent",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            physioType, // send which physio type was selected to the server
          }),
        }
      );

      const { clientSecret } = await response.json();
      setClientSecret(clientSecret);
      setIsPaymentIntentCreated(true);
    } catch (error) {
      console.error("Error creating payment intent:", error);
    }
  };

  const options = {
    clientSecret,
  };

  return (
    <div>
      <h3>Booking Page</h3>
      <p>
        {patient} booking in with {practitioner}
      </p>

      {/* Dropdowns and other buttons... */}

      {/* Input fields for start and end time */}
      <label>Start Time:</label>
      <input
        type="datetime-local"
        value={appStartTime}
        onChange={(e) => setAppStartTime(e.target.value)}
      />
      <br />

      <label>End Time:</label>
      <input
        type="datetime-local"
        value={appEndTime}
        onChange={(e) => setAppEndTime(e.target.value)}
      />
      <br />

      {/* New button to book appointment with start and end time */}
      <button onClick={handleBookWithTime}>Book with Start and End Time</button>
      {isPaymentIntentCreated && clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm clientSecret={clientSecret} />
        </Elements>
      )}
    </div>
  );
};

export default BookingPage;
