import React from 'react';
import './physioContent.css';

const PhysioProgramming = ({ patient }) => {
  const currentPatient = patient[0]; // Assuming we are working with the first patient in the array
  const programming = currentPatient.Programming;

  return (
    <div className="container">
      <h2>Physio Programming</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Exercise</th>
            <th>Sets</th>
            <th>Reps</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {programming.map((program, index) => (
            <tr key={index}>
              <td>{program.Exercise}</td>
              <td>{program.Sets}</td>
              <td>{program.Reps}</td>
              <td>{program.Notes}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PhysioProgramming;
