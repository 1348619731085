import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import PatientDropdown from "./PatientDropdown";
import PatientTrainingStatus from "./PatientTrainingStatus";
import NewAlerts from "./NewAlerts";
import NotesAndHistory from "./NotesAndHistory";
import TrainingSchedule from "./TrainingSchedule";
import PhysioProgramming from "./PhysioProgramming";
import PhysioVideos from "./PhysioVideos";
import Profilecontent from "./profilepage";
import About from "./about";
import PrivacyPolicyContent from "../privacyPolicy";
import "./mainContent.css";

const PhysioMainContent = (dataID) => {
  const [selectedLink, setSelectedLink] = useState("home");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientData, setPatientData] = useState(null);
  let contentComponent;
  const handlePatientSelect = async (patient) => {
    setSelectedPatient(patient);
    if (patient && patient.ptID) {
      try {
        const response = await fetch(
          `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/physio/PTData/get?userID=${patient.ptID}`
        );
        const data = await response.json();
        setPatientData(data);
      } catch (error) {
        console.error("Error fetching patient data:", error);
      }
    }
  };

  const refreshPTData = async () => {
    if (selectedPatient && selectedPatient.ptID) {
      try {
        const response = await fetch(
          `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/physio/PTData/get?userID=${selectedPatient.ptID}`
        );
        const data = await response.json();
        setPatientData(data);
      } catch (error) {
        console.error("Error fetching patient data:", error);
      }
    }
  };

  useEffect(() => {
    if (selectedPatient) {
      handlePatientSelect(selectedPatient);
    }
  }, [selectedPatient]);

  switch (selectedLink) {
    case "home":
      contentComponent = (
        <div className="main-content" style={{ backgroundImage: `url(/backgroundSq.svg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <button onClick={refreshPTData}>Refresh Data</button>
          <PatientDropdown onSelect={handlePatientSelect} dataID={dataID} />
          {patientData && (
            <>
              <h2 className="patientName">{selectedPatient.ptName}</h2>
              <NewAlerts patient={patientData} />
              <PhysioProgramming patient={patientData} />
              <PhysioVideos patient={patientData} />
            </>
          )}
        </div>
      );
      break;

    case "training":
      contentComponent = (
        <div className="main-content" style={{ backgroundImage: `url(/backgroundSq.svg)`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100vh' }}>
          <button onClick={refreshPTData}>Refresh Data</button>
          <PatientDropdown onSelect={handlePatientSelect} dataID={dataID} />
          {patientData && (
            <>
              <h2 className="patientName">{selectedPatient.ptName}</h2>
              <PatientTrainingStatus patient={patientData} />
              <TrainingSchedule patient={patientData} />
            </>
          )}
        </div>
      );
      break;

    case "about":
      contentComponent = (
        <div className="main-content" style={{ backgroundImage: `url(/backgroundSq.svg)`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
          <About />
        </div>
      );
      break;

    case "profile":
      contentComponent = (
        <div className="main-content" style={{ backgroundImage: `url(/backgroundSq.svg)`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100vh' }}>
          <Profilecontent dataID={dataID} />
        </div>
      );
      break;
    case "privacy":
      contentComponent = <PrivacyPolicyContent />;
      break;
    default:
      contentComponent = null;
  }

  const handleLinkClick = (link) => {
    setSelectedLink(link);
  };

  return (
    <div>
      <Header onLinkClick={handleLinkClick} />
      {contentComponent}
      <Footer onLinkClick={handleLinkClick} />
    </div>
  );
};

export default PhysioMainContent;
