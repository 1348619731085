import React, { useState } from "react";
import Header from "./athleteheader";
import Footer from "./footer";
import TodaysTrainingPlan from "./TodaysTrainingPlan";
import WodSelector from "./WodSelector";
import AdviceContent from "./advice";
import PhysioContent from "./physioContent/physioContent";
import Contact from "./contact";
import Profilecontent from "./profilepage";
import About from "./about";
import PrivacyPolicyContent from "../privacyPolicy";
import "./mainContent.css";

const AthleteMainContent = (dataID) => {
  const [selectedLink, setSelectedLink] = useState("home");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  let contentComponent;

  switch (selectedLink) {
    case "home":
      contentComponent = (
        <div className="main-content" style={{ backgroundImage: `url(/backgroundTri.svg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <TodaysTrainingPlan userID={dataID} />
          <WodSelector />
        </div>
      );
      break;
    case "advice":
      contentComponent = (
        <div className="main-content" style={{ backgroundImage: `url(/backgroundTri.svg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <AdviceContent />
        </div>
      );
      break;
    case "physio":
      contentComponent = (
        <div className="main-content" style={{ backgroundImage: `url(/backgroundTri.svg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <PhysioContent userID={dataID} />
        </div>
      );
      break;
    case "about":
      contentComponent = (
        <div className="main-content" style={{ backgroundImage: `url(/backgroundTri.svg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <About />
        </div>
      );
      break;
    case "profile":
      contentComponent = (
        <div className="main-content" style={{ backgroundImage: `url(/backgroundTri.svg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <Profilecontent userID={dataID}/>
        </div>
      );
      break;
    case "privacy":
      contentComponent = <PrivacyPolicyContent />;
      break;
    default:
      contentComponent = null;
  }

  const handleLinkClick = (link) => {
    setSelectedLink(link);
  };

  return (
    <div>
      <Header onLinkClick={handleLinkClick} isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen}/>
      <div style={{ marginTop: isMobileMenuOpen ? '250px' : '0' }}>
        {contentComponent}
      </div>
      <Footer onLinkClick={handleLinkClick} />
    </div>
  );
};

export default AthleteMainContent;
