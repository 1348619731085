import React, { useEffect, useState } from "react";
import Profile from "../../login/profile";
import CryptoJS from "crypto-js";

const Profilecontent = ({ dataID }) => {
  const [publicPhysioID, setPublicPhysioID] = useState("");
  const [joinRequests, setJoinRequests] = useState([]);
  const [userDetails, setUserDetails] = useState({});

  const decryptField = (encryptedValue, encryptionKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, encryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  const encryptField = (value, encryptionKey) => {
    return CryptoJS.AES.encrypt(value, encryptionKey).toString();
  };

  useEffect(() => {
    const fetchPublicPhysioID = async () => {
      try {
        const userID = dataID.dataID;
        const response = await fetch(
          `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/physio/getPublicID?physioID=${userID}`
        );
        const data = await response.json();
        setPublicPhysioID(data.publicPhysioID);
        setJoinRequests(data.JoinRequests || []);
        console.log('data', data)
      } catch (error) {
        console.error("Error fetching publicPhysioID:", error);
      }
    };

    fetchPublicPhysioID();
  }, [dataID]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        console.log('getting join Requests')
        const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
        const requests = joinRequests.map(async (requestID) => {
          const response = await fetch(
            `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/joinPhysio/getUsers?dataID=${requestID}`
          );
          const userData = await response.json();
          console.log(userData)
          const decryptedName = decryptField(userData.name, encryptionKey);
          return { [requestID]: decryptedName };
        });
        console.log('requsts:' , requests)
        const allUserData = await Promise.all(requests);
        const userDetailsMap = Object.assign({}, ...allUserData);
        setUserDetails(userDetailsMap);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    if (joinRequests.length > 0) {
      fetchUserDetails();
    }
  }, [joinRequests]);

  const handleRequestAction = async (requestID, moveRequest) => {
    try {
        const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
        const enName = encryptField(userDetails[requestID], encryptionKey)
      const requestBody = {
        physioID: dataID.dataID,
        ptName: enName,
        requestID: requestID,
        moveRequest: moveRequest,
      };

      const response = await fetch(
        "https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/joinPhysio/physioAction",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        console.log(
          `${moveRequest ? "Accepted" : "Denied"} request ID: ${requestID}`
        );
        // Optionally, you could remove the request from the list after handling it
        setJoinRequests(joinRequests.filter((id) => id !== requestID));
      } else {
        console.error("Failed to process the request");
      }
    } catch (error) {
      console.error("Error handling the request:", error);
    }
  };

  const handleAccept = (requestID) => {
    handleRequestAction(requestID, true);
  };

  const handleDeny = (requestID) => {
    handleRequestAction(requestID, false);
  };

  return (
    <div>
      <h3>Profile </h3>
      <p>Public Physio ID: {publicPhysioID}</p>
      {joinRequests.length > 0 && (
        <div className="joinReqContainer">
          <h4>New Join Requests ({joinRequests.length})</h4>
          <ul>
            {joinRequests.map((requestID) => (
              <li key={requestID}>
                {userDetails[requestID] ? userDetails[requestID] : "Loading..."}
                <button onClick={() => handleAccept(requestID)}>Accept</button>
                <button onClick={() => handleDeny(requestID)}>Deny</button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <Profile />
    </div>
  );
};

export default Profilecontent;
